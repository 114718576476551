@import 'Colours';

.link {
  font-weight: 500;
  color: $directpay-primary;
  text-decoration: none;

  &:hover {
    text-decoration: underline !important;
  }

  .newTabIcon {
    margin-left: 0.5ch;
  }
}

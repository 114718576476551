@import 'Colours';

#nav {
  --link-y-padding: 0.5em;
  --link-x-padding: 0.85em;
  width: 100%;
  position: sticky;
  top: 0;
  background-color: $directpay-white-main;
  z-index: 10;
  border-bottom: 1px solid $nav-light-gray;
}

.navContent {
  margin: 0 auto;
}

.logoContainer {
  display: flex;
  align-items: center;
  column-gap: 10px;
}

#Links a,
#Links button,
#Hamburger {
  cursor: pointer;
  border: none;
  background: none;
  color: inherit;
  font-weight: inherit;

  &:hover {
    color: inherit;
  }
}

#Links > div {
  z-index: 10;
  a,
  button, #LogoutBtn, .HelpCentreButton {
    padding: var(--link-y-padding) var(--link-x-padding);
    box-sizing: content-box;
    position: relative;
    text-align: start;
    text-decoration: none !important;
    opacity: 0.8;
    &:hover, &:focus{
      opacity: 1;
    }
  }
  .HelpCentreButton, #LogoutBtn {
    font-weight: 500 !important;
    font-family: inherit;
    font-size: inherit;
    text-align: start;
  }
}

.logo {
  display: block;
  aspect-ratio: 232 / 93;
  height: 2.5em;
  position: relative;
}

.text {
  color: black;
  font-size: 1.2em;
  font-weight: 500;
  margin-bottom: 0;
}

.env {
  opacity: 0.8;
  font-weight: 500;
  font-size: 0.8em;
}

.row {
  height: 3.75em;
  align-items: center;
}

.currentPage {
  opacity: 1 !important;
  color: $directpay-primary !important;
  border-bottom: 2px solid $directpay-primary !important;
}

@media only screen and (min-width: 700px) {
  #nav {
    transition: background-color 0.25s;
    &.atTop {
      background-color: $directpay-light-blue;
      border-bottom: none;

      .text,
      & ~ .navContent .text {
        height: 0;
      }
    }
  }

  .navContent {
    padding: 0 1rem;
    font-size: 0.9em;
  }

  .corners {
    display: none;
  }

  .logo {
    margin-left: 0;
  }

  .row {
    display: flex;
    width: 100%;
    justify-content: space-between;
    font-weight: 500;
    color: black;
  }

  #Links > div {
    display: flex;
    align-items: center;
    gap: 1em;

    #SignUpBtn {
      background-color: $directpay-primary;
      color: $directpay-white-main;
      border-radius: 30px;
      line-height: 1;
      padding: 0.5em 2em;
      height: 1.4em;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 5em;
      transition: background-color 0.1s;
      opacity: 1 !important;

      &:hover {
        background-color: $directpay-primary-hover;
        text-decoration: none !important;
      }
      &:focus {
        background-color: $directpay-primary-hover;
        text-decoration: none !important;
        box-shadow: 0 0 0 0.2rem #27245b81 !important;
      }
    }
  }

  .text {
    overflow: hidden;
  }
}

@media only screen and (max-width: 699.5px) {
  #nav {
    --link-y-padding: 0.3em;
    --link-x-padding: 1em;
    border-bottom: 1px solid $nav-light-gray;
  }

  .currentPage {
    border-bottom: none !important;
  }

  .navContent {
    padding: 0;
  }

  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .corners {
    width: 1.25em;
    height: 1.25em;
    margin: 0.625em 0.5em;
    font-size: 1.5em;
    &:first-child {
      margin-right: 0;
    }
    &:nth-child(3) {
      margin-left: 0;
    }
  }

  #Hamburger {
    padding: 2px 5px 5px 5px;
    opacity: 0.8;
  }

  .text {
    text-align: center;
    visibility: hidden;
  }
  .text.show {
    visibility: visible;
  }

  #Links {
    flex-basis: 100%;
    visibility: hidden;
    display: grid;
    transition: grid-template-rows 0.3s, visibility 0.3s;
    grid-template-rows: 0fr;

    & > div {
      overflow: hidden;
      background-color: $directpay-white-main;
      border-bottom: 1px solid $nav-light-gray;
      a,
      button {
        display: block;
        width: 100%;
        text-align: left;
        transition: background-color 0.25s;
        font-weight: 500;
      }
    }
  }

  #nav ~ .navContent .text {
    display: block;
  }

  .navContent.expanded #Links {
    visibility: visible;
    grid-template-rows: 1fr;
  }
}

// 28rem is approximatly 450px for typical default font size of 16px
// adding 650px limit in case default font size is too big where 28rem > 650px
@media screen and (max-width: 699.5px) and (max-width: 28rem) {
  .logoContainer {
    flex-direction: column;

    .logo {
      height: 2em;
    }
    .text {
      font-size: 1em;
    }
  }
}

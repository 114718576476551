@import "Colours";

#SkipTo {
  position: fixed;
  top: 0;
  left: 50%;
  z-index: 9999;
  transform: translate(-50%, -100%);
  transition: transform 0.3s;
  text-align: center;
  width: 100%;
  &:focus-within {
    transform: translate(-50%, -10%);
  }

  div{
    background-color: $directpay-primary;
    padding: 5px 3px;
    display: inline-block!important;
    border-radius: 5px;
    margin-left: 2px;
    &:hover {
      background-color: $directpay-primary-hover;
    }
  }

  button {
    background-color: transparent;
    color: white;
    padding: 5px;
    margin: 0 2.5px;
    border-radius: 5px;
    border: 1px solid transparent !important;
    font-size: 1.25em;
    font-weight: normal;

      &:focus {
        border: 1px solid #ffff !important;
      }
      &:hover {
        background-color: $directpay-primary-hover;
      }
  }
}
